import React from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCategoryDataThunk } from '../redux/TranslationsReducer/translationsActions';

import logo from '../assets/Logo CoolPass.png';
import AppstoreQR from '../assets/AppleStore_QR 1.png';
import GooglePlayQR from '../assets/GooglePlay_QR 1.png';
import PhoneMockup from '../assets/Phone mockup.png';
import FooterImage from '../assets/illustrace CoolPass.png';
import LanguageSelector from './LanguageSelector';
import Phone from '../assets/App Design.png';
import PhoneIcons from '../assets/About Our App.png';

import AppleIcon from '../assets/icons8-apple-logo-100.png';
import GooglePlayIcon from '../assets/icons8-google-play-96.png';

import Klavir from '../assets/klavir_CP.png';
import Hrad from '../assets/hrad_CP.png';
import Jidlo from '../assets/jidlo_CP.png';
import Muzeum from '../assets/muzeum_CP 1.png';
import Autobus from '../assets/autobus 1.png';

import '../styles/App.css';

function Landing() {
    const defaultLanguage = 'en';

    const dispatch = useDispatch();
    const translationsData = useSelector((state) => state.translationsReducer.translationsCategoryData);
    const currentLanguage = useSelector((state) => state.language.language);

    useEffect(() => {
        dispatch(getCategoryDataThunk());
    }, []);

    const getTranslation = (key) => {
        if(translationsData.length > 0){
            const translation = translationsData.find((item) => item.key === key);
    
            return translation ? translation.value[currentLanguage] : translation.value[defaultLanguage];
        }
    };

    return (
        <body>
            <div className='mobile-container'>
                <div className='gradient-container'>
                    <header>
                        <img className='logo' src={logo} alt='logo' draggable={false}/>
                        <LanguageSelector />
                    </header>
                    <div className='main-content-container'>
                        <div className='squares-container'>
                            <div className='empty-square'></div>
                            <div className='content-square'>
                                <div className='content-square-container'>
                                    <h1 className='mobile-title'>{getTranslation('MOBILE_TITLE')}<span>{getTranslation('MOBILE_TITLE_SPAN')}</span></h1>
                                    <h3 className='mobile-subtitle'>{getTranslation('MOBILE_SUBTITLE')}</h3>

                                    <h2 className='mobile-application-text'>{getTranslation('MOBILE_APPLICATION_TEXT')}</h2>
                                    <div className='mobile-application-buttons-container'>
                                        <a href='https://play.google.com/store/apps/details?id=com.coolpass.cz&hl=en'>
                                            <div className='mobile-qr-button'>
                                                <img className='mobile-qr-code-button-image' src={GooglePlayIcon} draggable={false}/>
                                                <div className='mobile-qr-button-text-container'>
                                                    <p className='mobile-qr-button-text'>{getTranslation('GOOGLE_PLAY_TEXT')}</p>
                                                    <p className='mobile-qr-button-app-name'>{getTranslation('GOOGLE_PLAY')}</p>
                                                </div>
                                            </div>
                                        </a>
                                        <a href='https://apps.apple.com/app/coolpass-cz-beta/id6474856134'>
                                            <div className='mobile-qr-button'>
                                                <img className='mobile-qr-code-button-image' src={AppleIcon} draggable={false}/>
                                                <div className='mobile-qr-button-text-container'>
                                                    <p className='mobile-qr-button-text'>{getTranslation('APP_STORE_TEXT')}</p>
                                                    <p className='mobile-qr-button-app-name'>{getTranslation('APP_STORE')}</p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='mobile-footer-container'>
                    <img src={Phone}/>
                    <img className='mobile-phone-icons' src={PhoneIcons}/>
                </div>
            </div>


            <header className='desktop-header'>
                <img className='logo' src={logo} alt='logo' draggable={false}/>
                <LanguageSelector />
            </header>
            <main className='desktop-main'>
                <div className="content">
                    <h1 className='desktop-title'>{getTranslation('TITLE')}<span>{getTranslation('TITLE_SPAN')}</span></h1>
                    <h2>{getTranslation('SUBTITLE')}</h2>
                    <div className="qr-codes">
                        <div className="qr-code">
                            <img className='qr-code-image' src={AppstoreQR} draggable={false}/>
                            <a href='https://apps.apple.com/app/coolpass-cz-beta/id6474856134'>
                                <div className='qr-button'>
                                    <img className='qr-code-button-image' src={AppleIcon} draggable={false}/>
                                    <div className='qr-button-text-container'>
                                        <p className='qr-button-text'>{getTranslation('APP_STORE_TEXT')}</p>
                                        <p className='qr-button-app-name'>{getTranslation('APP_STORE')}</p>
                                    </div>
                                </div>
                            </a>
                            {/* <button>Prozkoumat nabídky</button> */}
                        </div>
                        <div className="qr-code">
                            <img className='qr-code-image' src={GooglePlayQR} draggable={false}/>
                            <a href='https://play.google.com/store/apps/details?id=com.coolpass.cz&hl=en'>
                                <div className='qr-button'>
                                    <img className='qr-code-button-image' src={GooglePlayIcon} draggable={false}/>
                                    <div className='qr-button-text-container'>
                                        <p className='qr-button-text'>{getTranslation('GOOGLE_PLAY_TEXT')}</p>
                                        <p className='qr-button-app-name'>{getTranslation('GOOGLE_PLAY')}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="image-container">
                    <div className='image-container-wrapper'>
                        <img className='general-image' src={PhoneMockup} alt="Image" draggable={false}/>
                        <div className='small-button button1'><p>{getTranslation('DISCOUNT_TEXT_1')}</p></div>
                        <div className='small-button button2'><p>{getTranslation('DISCOUNT_TEXT_2')}</p></div>

                        <div className='small-button button3'><img src={Klavir}/></div>
                        <div className='small-button button4'><img src={Hrad}/></div>
                        <div className='small-button button5'><img src={Jidlo}/></div>
                        <div className='small-button button6'><img src={Muzeum}/></div>
                        <div className='small-button button7'><img src={Autobus}/></div>
                    </div>
                    
                    
                    {/* <img className='icons' src={Icons} alt="Icons" draggable={false}/> */}
                </div>
            </main>
            <footer className="footer">
                <img src={FooterImage} alt="Картинка" className="footer-image" draggable={false}/>
            </footer>
        </body>
    );
}

export default Landing;
