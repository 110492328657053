import React, { useEffect, useRef, useState } from 'react';
import  '../styles/LanguageSelector.css';
import MenuIcon from '../assets/94 - Menu.png';
import ChechLanguage from '../assets/languages/Czech republic.png';
import EnglishLanguage from '../assets/languages/united-kingdom.png';
import GermanLanguage from '../assets/languages/German.png';
import PolandLanguage from '../assets/languages/Poland.png';
import RussianLanguage from '../assets/languages/Russian.png';
import { useDispatch } from 'react-redux';
import { setLanguage } from '../redux/LanguagesReducer/languagesActions';

const languageIcons = {
  cs: ChechLanguage,
  en: EnglishLanguage,
  de: GermanLanguage,
  pl: PolandLanguage,
  ru: RussianLanguage
};

const supportedLanguages = ['cs', 'en', 'de', 'pl', 'ru'];

const LanguageSelector = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('en');

  const dropdownRef = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    const browserLanguage = navigator.language.split('-')[0]; // Get the primary language part
    const initialLanguage = supportedLanguages.includes(browserLanguage) ? browserLanguage : 'en';
    setSelectedLanguage(initialLanguage);
    dispatch(setLanguage(initialLanguage));

    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, dispatch]);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  const handleLanguageClick = (lang) => {
    setSelectedLanguage(lang);
    dispatch(setLanguage(lang));
    setDropdownOpen(false);
  };

  return (
    <div className="language-selector">
      <img src={MenuIcon} className='menu-icon' alt='menu-icon' onClick={toggleDropdown}/>
      <div className="language-icon">
        <img src={languageIcons[selectedLanguage]} alt="Current Language" />
      </div>
      {isDropdownOpen && (
        <div className="language-dropdown" ref={dropdownRef}>
          <ul>
            <li onClick={() => handleLanguageClick('cs')}><img src={ChechLanguage} alt="Chech" /></li>
            <li onClick={() => handleLanguageClick('en')}><img src={EnglishLanguage} alt="English" /></li>
            <li onClick={() => handleLanguageClick('de')}><img src={GermanLanguage} alt="German" /></li>
            <li onClick={() => handleLanguageClick('pl')}><img src={PolandLanguage} alt="Polish" /></li>
            <li onClick={() => handleLanguageClick('ru')}><img src={RussianLanguage} alt="Russian" /></li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;