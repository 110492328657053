// translationsActions.js
import { getTranslationCategoryApi } from "../../api/translationsApi";

export const SET_CATEGORY_DATA = 'translations/setCategoryData';
export const SET_IS_LOADING_CATEGORY_DATA = 'translations/setIsLoadingCategoryData';

const setCategoryData = (data) => {
  return { type: SET_CATEGORY_DATA, payload: { data } };
};

const setIsLoadingCategoryData = (isLoading) => {
  return { type: SET_IS_LOADING_CATEGORY_DATA, isLoading };
};

export const getCategoryDataThunk = () => async (dispatch, getState) => {
  dispatch(setIsLoadingCategoryData(true));
  const { language } = getState().language;
  const response = await getTranslationCategoryApi(language);
  if (response.status >= 200 && response.status < 300) {
    dispatch(setCategoryData(response.data));
  }
  dispatch(setIsLoadingCategoryData(false));
};