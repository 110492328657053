// translationsReducer.js
import {
  SET_CATEGORY_DATA,
  SET_IS_LOADING_CATEGORY_DATA,
} from './translationsActions';

const initialState = {
  translationsCategoryData: [],
  isLoadingCategoryData: false,
};

const translationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CATEGORY_DATA:
      return {
        ...state,
        translationsCategoryData: Array.isArray(action.payload.data) ? [...action.payload.data] : [],
      };
    case SET_IS_LOADING_CATEGORY_DATA:
      return {
        ...state,
        isLoadingCategoryData: action.isLoading,
      };
    default:
      return state;
  }
};

export default translationsReducer;
