import { applyMiddleware, combineReducers, createStore, compose } from 'redux';
import { thunk } from 'redux-thunk';
import translationsReducer from './TranslationsReducer/translationsReducer';
import languageReducer from './LanguagesReducer/languagesReducer';

let reducerAll = combineReducers({
  translationsReducer: translationsReducer,
  language: languageReducer
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  reducerAll,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
